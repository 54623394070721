import contentGeneralRepository from '@/repository/contentGeneralRepository'
import store from '@/store'

export default function contentGeneralService() {
  const getElement = id => new Promise(resolve => {
    contentGeneralRepository.getElement(id)
      .then(res => {
        if (!res) {
          throw new Error('Статьи с текущем идентификатором не найдено')
        }
        resolve(res)
      })
      .catch(error => {
        store.commit('app/SET_ERROR', error)
        resolve(null)
      })
  })

  return { getElement }
}
