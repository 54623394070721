<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        src="@/assets/images/logo/logo.png"
        alt="statuspro.club/"
        class="navbar-brand-full"
      /></b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Политика конфиденциальности
        </h2>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="element.detailText" />

      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
import contentGeneralService from '@/application/contentGeneralService'
import { ref } from '@vue/composition-api'
import router from '@/router'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    const element = ref({})
    const { getElement } = contentGeneralService()

    getElement(2).then(res => {
      if (!res) {
        router.replace({ name: 'error-404' })
        return
      }
      element.value = res
    })

    return { element }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
